<template>
  <div class="container">
      <header>Inscription-online.</header>
      <form>
        <div class="input-field">
          <input v-model="identifiant" type="text" required>
          <label>Identifiant</label>
        </div>
        <div class="input-field">
          <input v-model="mdp" class="pswrd" type="password" required>
          <label>Mot de passe</label>
        </div>
        <div class="button">
          <div class="inner"></div>
          <button @click.prevent="connexion()">Connexion</button>
        </div>
      </form>
    </div>
</template>

<!-- <template>
  <div class="main">
    <h1 class="text-uppercase mb-5">Bienvenue<br>Taekwondo Élite cergy</h1>
    <input
      v-model="identifiant"
      type="text"
      id="username"
      placeholder="Identifiant"
    />
    <br />
    <input
      v-model="mdp"
      type="password"
      id="password"
      placeholder="Mot de passe"
    />
    <br />
    <input
      type="button"
      value="CONNEXION"
      class="button"
      id="done"
      @click="connexion()"
    />
  </div>
</template> -->

<script>
import Users from "@/services/User"
// import Api from "../../../../config/config.js"
import { mapActions } from "vuex";
export default {
  name: "Login",
  data:()=>(
     {
      identifiant:"",
      mdp:"",
      }),

  methods:{
    ...mapActions(["LogIn"]),
    async connexion(){
      
  
      if(this.identifiant!=="" && this.mdp!==""){
       const userGet = await Users.get({
        identifiant:this.identifiant,
        mdp:this.mdp
       }) 
       console.log("userGet",userGet)
       if(userGet){
        localStorage.setItem("token", userGet.token);
        localStorage.setItem("refreshToken", userGet.refreshToken);
        this.LogIn(true)
        this.$store.state.currentUser = userGet
        if(1 >= userGet.user.autorisation){
          this.$router.push("/");
        }else{
          this.$router.push("/pages/user");
        }
        
       }else{
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        this.LogIn(false)
        this.identifiant=""
        this.mdp=""
       }
      }
    }
  }
  };
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Montserrat:600|Noto+Sans|Open+Sans:400,700&display=swap');


#login-container{
  margin: 0;
  padding: 0;
  border-radius: 5px;
  box-sizing: border-box;
}
#login-container{
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: sans-serif;
  justify-content: center;
  background: url("../.././../assets/bg.jpg");
  background-size: cover;
  background-position: center;
}
#login-container{
  .container{
  position: relative;
  max-width: 300px;
  background: white;
  padding: 40px 20px;
  border-radius: 20px;
}
header{
  font-size: 25px;
  margin-bottom: 35px;
  font-family: 'Montserrat', sans-serif;
}
.input-field, form .button{
  margin: 25px 0;
  position: relative;
  height: 50px;
  width: 100%;
}
.input-field input{
  height: 100%;
  width: 100%;
  border: 1px solid silver;
  padding-left: 15px;
  outline: none;
  font-size: 19px;
  transition: .4s;
}
input:focus{
  border: 1px solid #1DA1F2;
}
.input-field label, span.show{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.input-field label{
  left: 15px;
  pointer-events: none;
  color: grey;
  font-size: 18px;
  transition: .4s;
}
span.show{
  right: 20px;
  color: #111;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  visibility: hidden;
  font-family: 'Open Sans', sans-serif;
}
input:valid ~ span.show{
  visibility: visible;
}
input:focus ~ label,
input:valid ~ label{
  transform: translateY(-33px);
  background: white;
  font-size: 16px;
  color: #1DA1F2;
}
form .button{
  margin-top: 30px;
  overflow: hidden;
  z-index: 111;
}
.button .inner{
  position: absolute;
  height: 100%;
  width: 300%;
  left: -100%;
  z-index: -1;
  transition: all .4s;
  background: -webkit-linear-gradient(right,#00dbde,#fc00ff,#00dbde,#fc00ff);
}
.button:hover .inner{
  left: 0;
}
.button button{
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  outline: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}
.container .auth{
  margin: 35px 0 20px 0;
  font-size: 19px;
  color: grey;
}
.links{
  display: flex;
  cursor: pointer;
}
.facebook, .google{
  height: 40px;
  width: 100%;
  border: 1px solid silver;
  border-radius: 3px;
  margin: 0 10px;
  transition: .4s;
}
.facebook:hover{
  border: 1px solid #4267B2;
}
.google:hover{
  border: 1px solid #dd4b39;
}
.facebook i, .facebook span{
  color: #4267B2;
}
.google i, .google span{
  color: #dd4b39;
}
.links i{
  font-size: 23px;
  line-height: 40px;
  margin-left: -90px;
}
.links span{
  position: absolute;
  font-size: 17px;
  font-weight: bold;
  padding-left: 8px;
  font-family: 'Open Sans', sans-serif;
}
.signup{
  margin-top: 50px;
  font-family: 'Noto Sans', sans-serif;
}
.signup a{
  color: #3498db;
  text-decoration: none;
}
.signup a:hover{
  text-decoration: underline;
}
}

</style>
<!-- <style scoped>

.main {
  background: rgba(255, 255, 255, 0.2);
  /* position: absolute;
  top: 20%;
  left: 30%; */
  width: 100%;
  text-align: center;
  padding: 5px;
  border-radius: 1rem;
  box-shadow: 0px 0px 8px -5px #000000;
  padding-top: 3%;
  padding-bottom: 5%;
  font-family: "Poppins", sans-serif;
}

h1 {
  cursor: default;
  user-select: none;
  font-weight: bold;
  color:#ffffff;
  font-size:1.8em;
}

input {
  /* border:1px solid rgb(110, 140, 170); */
  border-radius: 3rem;
  /* border: none; */
  padding: 10px;
  text-align: center;
  outline: none;
  margin: 10px;
  width: 50%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  box-shadow: 0px 0px 8px -5px #000000;
  background-color: #ffffff;
}

input:hover {
  box-shadow: 0px 0px 8px -5px #000000;
}

input:active {
  box-shadow: 0px 0px 8px -5px #000000;
}

input:focus {
  box-shadow: 0px 0px 8px -5px #000000;
}

#done {
  border:none;
  background: rgb(111, 182, 111);
  color:#ffffff;
  font-weight: bold;
  width:50%;
}

.button {
  cursor: pointer;
  user-select: none;
}

img {
  height: 2.2rem;
  margin: 10px;
  user-select: none;
}

img:hover {
  box-shadow: 0px 0px 8px -5px #000000;
  cursor: pointer;
  border-radius: 200rem;
}
</style> -->